import { type Directive } from 'vue'
import { formatStrTime } from '@/utils/date'

/** 权限指令，和权限判断函数 checkPermission 功能类似 ,
 * form使用的model需要被defineExpose抛出来 */
export const directives: Directive = {
  mounted(el, binding, vnode) {
    // console.log('dateFormat el, binding, vnode === ', el, binding, vnode)
    // console.log('dateFormat children === ', el.children[1].children[0])
    // console.log('dateFormat parentNode === ', el.parentNode)
    const field = vnode?.ctx?.props?.prop
    // console.log('field == ', field)

    // const { value: _obj } = binding
    // console.log('value == ', _obj)
    // const { context: that } = vnode
    // console.log('dateFormat that === ', that)
    // const { expression: key } = data.model
    // if (that && that._isVue) {
    // console.log(el)
    // const $this = el?.children?.[1]?.children?.[0]
    // console.log('dateFormat $this === ', $this)
    const domm = el?.children?.[1]?.children?.[0]?.children?.[0]?.children?.[1]
    console.log('domm == ', domm)
    // console.log('domm.form == ', domm.form)
    // console.log('domm.form?.model == ', domm.form?.model)
    // console.log('domm.form.elements == ', domm.form.elements)
    // const elementDom = {}
    // for (let i = 0; i < domm.form.elements.length; i++) {
    //   const ele = domm.form.elements[i]
    //   console.log('element == ', ele)
    //   console.log('element.type == ', ele.type)
    //   console.log('element.value == ', ele.value)
    //   console.log('element.name == ', ele.name)
    //   if (ele.name && ele.type === 'text') {
    //     elementDom[ele.name] = ele

    //     // ele.value = '2020-01-02'
    //   }
    // }
    // $this.onchange = function () {
    if (!field || !domm) {
      return
    }
    domm.onchange = function () {
      // binding.instance[field] = '2020-01-01'
      // let valuex = $this.value //中文日期
      // console.log('valuex', valuex, $this)
      // const domm = $this.children[0].children[1]
      // console.log('domm == ', domm)
      const valueDom = domm.value
      const valueName = domm.name
      console.log('domm.value == ', valueDom, typeof valueDom)
      console.log('valueName == ', valueName)
      // // valueDom = '2020-01-01'
      // console.log('binding == ', binding)
      console.log('binding.instance == ', binding.instance)
      console.log('binding.instance.model == ', binding.instance.model)
      // console.log(
      //   'binding.instance.model.value == ',
      //   binding.instance?.model?.value,
      // )
      const reg = /^\d{8}$/
      // console.log('reg.test(str) == ', reg.test(valueDom))
      // console.log(
      //   'formatStrTime == ',
      //   formatStrTime(valueDom, { dateFormat: 'YYYY-MM-DD' })
      // )
      // domm.value = '2020-09-09'
      // if (elementDom?.[valueName]) {
      //   console.log('elementDom[valueName] == ', elementDom[valueName])
      //   console.log(
      //     'elementDom[valueName].value == ',
      //     elementDom[valueName].value,
      //   )
      //   // elementDom[valueName].value = formatStrTime(elementDom[valueName].value, {
      //   //   dateFormat: 'YYYY-MM-DD',
      //   // })
      //   elementDom[valueName].blur()
      //   return
      // }
      // console.log('elementDom == ', elementDom)
      // console.log('domm.name == ', domm.name)
      // console.log(
      //   'JSON.parse.name == ',
      //   JSON.parse(domm.name.replace(/'/g, '"')),
      // )
      // console.log(
      //   'elementDom?.[valueName].name == ',
      //   elementDom?.[valueName].name,
      // )
      // console.log(
      //   'elementDom?.[valueName].alt == ',
      //   elementDom?.[valueName].alt,
      // )
      // console.log(
      //   'elementDom?.[valueName].tabindex == ',
      //   elementDom?.[valueName].tabindex,
      // )
      if (valueDom && reg.test(valueDom) && binding.instance.model) {
        if (valueName) {
          const obj = JSON.parse?.(valueName?.replace?.(/'/g, '"')) || {}
          const { name, formName, formIndex } = obj
          console.log(
            'name, formName, formIndex == ',
            name,
            formName,
            formIndex,
            binding.instance.model,
          )
          if (name && formName) {
            console.log(
              valueDom,
              formatStrTime(valueDom, {
                dateFormat: 'YYYY-MM-DD',
              }),
            )
            binding.instance.model[formName][formIndex][name] = formatStrTime(
              valueDom,
              {
                dateFormat: 'YYYY-MM-DD',
              },
            )
          }
        } else {
          binding.instance.model[field] = formatStrTime(valueDom, {
            dateFormat: 'YYYY-MM-DD',
          })
        }
        domm.blur()
      }

      // setTimeout(() => {
      // }, 5000)
      // const appC = $this.getCurrentInstance()
      // console.log('appC == ', appC)
      // const vnn = appC?.ctx[]
      // if (value.match(/\d{1,}/g) && value.length == 8) {
      //   value = value.replace(
      //     /^(\d{4})\D*(\d{1,2})\D*(\d{1,2})\D*/,
      //     '$1-$2-$3'
      //   ) //格式化输入格式
      //   const time =
      //     value && value.constructor == String ? new Date(value) : value //转换时间格式
      //   let keys = key.split('.') //自定义赋值
      //   if (_obj) {
      //     const { keys: keyList } = _obj
      //     keys = keyList
      //   }
      //   if (keys && keys.length >= 2) {
      //     const [key1, key2, key3, key4] = keys
      //     if (key4) {
      //       that[key1][key2][key3][key4] = time
      //     } else if (key3) {
      //       that[key1][key2][key3] = time
      //     } else {
      //       that[key1][key2] = time
      //     }
      //   } else {
      //     that[key] = time
      //   }
      // }
    }
    // }
  },
}
