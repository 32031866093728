import { type RouteRecordRaw, createRouter } from 'vue-router'
import { history, flatMultiLevelRoutes } from './helper'
import routeSettings from '@/config/route'

const Layouts = () => import('@/layouts/index.vue')
// 常量
export const INSTITUTION = 'institution'
export const CLINIC = 'clinic'
export const EMPLOYEE = 'employee'
export const ROLE = 'role'
export const DISEASE = 'disease'
export const OPTIONS = 'options'
export const USER = 'user'
export const PATIENT = 'patient'
export const PROJECT = 'project'
export const STATISTICS = 'statistics'
export const REVENUE = 'revenue'
export const ACUPUNCTURE = 'acupuncture'
export const BONING = 'boning'
export const CUPPING = 'cupping'
export const INVENTORY = 'inventory'
export const SYSTEM = 'system'
export const PERSONNEL = 'personnel'
export const DRUG = 'drug'
export const MENU = 'menu'
export const DOMAIN = 'domain'
export const STANDARD = 'standard'
export const ORDER = 'order'
export const COMMON = 'common'
export const DICT = 'dict'
export const THIRDPARTY = 'thirdparty'
export const AUTHORIZE = 'authorize'
/**
 * 自定义常态路由
 * 用来放置所有人访问的业务路由
 * 必须带有 Name 属性
 */
// export const constantCustomRoutes: RouteRecordRaw[] = [
// ]
/**
 * 常驻路由
 * 除了 redirect/403/404/login 等隐藏页面，其他页面建议设置 Name 属性
 */
export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/redirect',
    component: Layouts,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue'),
      },
    ],
  },
  {
    path: '/403',
    // component: () => import("@/views/error-page/403.vue"),
    component: () => retryImport('403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    // component: () => import("@/views/error-page/404.vue"),
    component: () => retryImport('404'),
    meta: {
      hidden: true,
    },
    alias: '/:pathMatch(.*)*',
  },
  {
    path: '/404-guide',
    // component: () => import("@/views/error-page/404-guide.vue"),
    component: () => retryImport('404-guide'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/login',
    // component: () => import("@/views/login/index.vue"),
    component: () => retryImport('login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/',
    component: Layouts,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        // component: () => import("@/views/dashboard/index.vue"),
        component: () => retryImport('dashboard'),
        name: 'Dashboard',
        meta: {
          title: '首页',
          // roles: ["admin"], // 或者在子导航中设置角色
          svgIcon: 'home',
          affix: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*', // Must put the 'ErrorPage' route at the end, 必须将 'ErrorPage' 路由放在最后
    redirect: '/404',
    name: 'ErrorPage',
    meta: {
      hidden: true,
    },
  },
  // ...constantCustomRoutes,
]

/**
 * 动态路由
 * 用来放置有权限 (Roles 属性) 的路由
 * 必须带有 Name 属性
 */
export const asyncRoutes: RouteRecordRaw[] = [
  {
    path: `/${INSTITUTION}`,
    component: Layouts,
    redirect: `/${INSTITUTION}/${CLINIC}`,
    name: 'Institution',
    meta: {
      title: '机构管理',
      svgIcon: 'clinic',
      roles: ['admin', 'editor'], // 可以在根路由中设置角色
      alwaysShow: true, // 将始终显示根菜单
    },
    children: [
      {
        path: `${CLINIC}`,
        component: () => retryImport(`${INSTITUTION}-${CLINIC}`),
        name: 'InstitutionClinic',
        meta: {
          title: '诊所管理',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
      {
        path: `${PERSONNEL}`,
        component: () => retryImport(`${INSTITUTION}-${PERSONNEL}`),
        name: 'personnel',
        meta: {
          title: '人员管理',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
    ],
  },
  // {
  //   path: `/${CLINIC}`,
  //   component: Layouts,
  //   redirect: `/${CLINIC}/${EMPLOYEE}`,
  //   name: 'Clinic',
  //   meta: {
  //     title: '诊所管理',
  //     svgIcon: 'lock',
  //     roles: ['admin', 'editor'], // 可以在根路由中设置角色
  //     alwaysShow: true, // 将始终显示根菜单
  //   },
  //   children: [
  //     {
  //       path: `${EMPLOYEE}`,
  //       // component: () => import("@/views/clinic/employee/index.vue"),
  //       component: () => retryImport(`${CLINIC}-${EMPLOYEE}`),
  //       name: 'ClinicEmployee',
  //       meta: {
  //         title: '员工管理',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //     {
  //       path: `${ROLE}`,
  //       // component: () => import("@/views/clinic/role/index.vue"),
  //       component: () => retryImport(`${CLINIC}-${ROLE}`),
  //       name: 'ClinicRole',
  //       meta: {
  //         title: '角色权限',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //     {
  //       path: `${DISEASE}`,
  //       // component: () => import("@/views/clinic/disease/index.vue"),
  //       component: () => retryImport(`${CLINIC}-${DISEASE}`),
  //       name: 'ClinicDisease',
  //       meta: {
  //         title: '匹配药物',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //     {
  //       path: `${OPTIONS}`,
  //       // component: () => import("@/views/clinic/options/index.vue"),
  //       component: () => retryImport(`${CLINIC}-${OPTIONS}`),
  //       name: 'ClinicOptions',
  //       meta: {
  //         title: '诊所配置',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: `/${USER}`,
  //   component: Layouts,
  //   redirect: `/${USER}/${PATIENT}`,
  //   name: 'User',
  //   meta: {
  //     title: '用户管理',
  //     svgIcon: 'lock',
  //     roles: ['admin', 'editor'], // 可以在根路由中设置角色
  //     alwaysShow: true, // 将始终显示根菜单
  //     controlled: true, // 模塊是否受權限控制
  //   },
  //   children: [
  //     {
  //       path: `${PATIENT}`,
  //       // component: () => import("@/views/user/patient/index.vue"),
  //       component: () => retryImport(`${USER}-${PATIENT}`),
  //       name: 'UserPatient',
  //       meta: {
  //         title: '患者管理',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //   ],
  // },
  {
    path: `/${INVENTORY}`,
    component: Layouts,
    redirect: `/${INVENTORY}/${DRUG}`,
    name: 'Inventory',
    meta: {
      title: '药品管理',
      svgIcon: 'inventory',
      roles: ['admin', 'editor'], // 可以在根路由中设置角色
      alwaysShow: true, // 将始终显示根菜单
    },
    children: [
      {
        path: `${DRUG}`,
        // component: () => import("@/views/inventory/drug/index.vue"),
        component: () => retryImport(`${INVENTORY}-${DRUG}`),
        name: 'InventoryDrug',
        meta: {
          title: '药品列表',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
      {
        path: `${STANDARD}`,
        // component: () => import("@/views/inventory/standard/index.vue"),
        component: () => retryImport(`${INVENTORY}-${STANDARD}`),
        name: 'InventoryStandard',
        meta: {
          title: '规格标准',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
      // {
      //   path: `${ORDER}`,
      //   // component: () => import("@/views/inventory/order/index.vue"),
      //   component: () => retryImport(`${INVENTORY}-${ORDER}`),
      //   name: 'InventoryOrder',
      //   meta: {
      //     title: '订货记录',
      //     roles: ['admin'], // 或者在子导航中设置角色
      //   },
      // },
    ],
  },
  {
    path: `/${SYSTEM}`,
    component: Layouts,
    redirect: `/${SYSTEM}/${MENU}`,
    name: 'System',
    meta: {
      title: '系统管理',
      svgIcon: 'system',
      roles: ['admin', 'editor'], // 可以在根路由中设置角色
      alwaysShow: true, // 将始终显示根菜单
    },
    children: [
      {
        path: `${MENU}`,
        component: () => retryImport(`${SYSTEM}-${MENU}`),
        name: 'SystemMenu',
        meta: {
          title: '菜单管理',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
      {
        path: `${DOMAIN}`,
        component: () => retryImport(`${SYSTEM}-${DOMAIN}`),
        name: 'SystemDomain',
        meta: {
          title: '解析管理',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
    ],
  },
  {
    path: `/${COMMON}`,
    component: Layouts,
    redirect: `/${COMMON}/${DICT}`,
    name: 'Common',
    meta: {
      title: '公共数据',
      svgIcon: 'system',
      roles: ['admin', 'editor'], // 可以在根路由中设置角色
      alwaysShow: true, // 将始终显示根菜单
    },
    children: [
      {
        path: `${DICT}`,
        component: () => retryImport(`${COMMON}-${DICT}`),
        name: 'CommonDict',
        meta: {
          title: '字典管理',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
    ],
  },
  {
    path: `/${THIRDPARTY}`,
    component: Layouts,
    redirect: `/${THIRDPARTY}/${AUTHORIZE}`,
    name: 'Thirdparty',
    meta: {
      title: '第三方',
      svgIcon: 'system',
      roles: ['admin', 'editor'], // 可以在根路由中设置角色
      alwaysShow: true, // 将始终显示根菜单
    },
    children: [
      {
        path: `${AUTHORIZE}`,
        component: () => retryImport(`${THIRDPARTY}-${AUTHORIZE}`),
        name: 'ThirdpartyAuthorize',
        meta: {
          title: '密钥管理',
          roles: ['admin'], // 或者在子导航中设置角色
        },
      },
    ],
  },
  // {
  //   path: `/${PROJECT}`,
  //   component: Layouts,
  //   redirect: `/${PROJECT}/${ACUPUNCTURE}`,
  //   name: 'Project',
  //   meta: {
  //     title: '项目管理',
  //     svgIcon: 'lock',
  //     roles: ['admin', 'editor'], // 可以在根路由中设置角色
  //     alwaysShow: true, // 将始终显示根菜单
  //   },
  //   children: [
  //     {
  //       path: `${ACUPUNCTURE}`,
  //       // component: () => import("@/views/project/acupuncture/index.vue"),
  //       component: () => retryImport(`${PROJECT}-${ACUPUNCTURE}`),
  //       name: 'ProjectAcupuncture',
  //       meta: {
  //         title: '针灸管理',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //     {
  //       path: `${BONING}`,
  //       component: () => retryImport(`${PROJECT}-${BONING}`),
  //       name: 'ProjectBoning',
  //       meta: {
  //         title: '推拿/正骨',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //     {
  //       path: `${CUPPING}`,
  //       component: () => retryImport(`${PROJECT}-${CUPPING}`),
  //       name: 'ProjectCupping',
  //       meta: {
  //         title: '拔罐管理',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: `/${STATISTICS}`,
  //   component: Layouts,
  //   redirect: `/${STATISTICS}/${REVENUE}`,
  //   name: 'Statistics',
  //   meta: {
  //     title: '查询统计',
  //     svgIcon: 'lock',
  //     roles: ['admin', 'editor'], // 可以在根路由中设置角色
  //     alwaysShow: true, // 将始终显示根菜单
  //   },
  //   children: [
  //     {
  //       path: `${REVENUE}`,
  //       // component: () => import("@/views/statistics/revenue/index.vue"),
  //       component: () => retryImport(`${STATISTICS}-${REVENUE}`),
  //       name: 'StatisticsRevenue',
  //       meta: {
  //         title: '客源营收',
  //         roles: ['admin'], // 或者在子导航中设置角色
  //       },
  //     },
  //   ],
  // },
]

const router = createRouter({
  history,
  routes: routeSettings.thirdLevelRouteCache
    ? flatMultiLevelRoutes(constantRoutes)
    : constantRoutes,
})

/** 重置路由 */
export function resetRouter() {
  // 注意：所有动态路由路由必须带有 Name 属性，否则可能会不能完全重置干净
  try {
    router.getRoutes().forEach((route) => {
      const { name, meta } = route
      if (name && meta.roles?.length) {
        router.hasRoute(name) && router.removeRoute(name)
      }
    })
  } catch {
    // 强制刷新浏览器也行，只是交互体验不是很好
    window.location.reload()
  }
}

const retryImportErrPath = {
  '403': 'src/views/error-page/403.vue',
  '404': 'src/views/error-page/404.vue',
  '404-guide': 'src/views/error-page/404-guide.vue',
  login: 'src/views/login/index.vue',
  dashboard: 'src/views/dashboard/index.vue',
  'institution-clinic': 'src/views/institution/clinic/index.vue',
  'institution-personnel': 'src/views/institution/personnel/index.vue',
  'clinic-employee': 'src/views/clinic/employee/index.vue',
  'clinic-role': 'src/views/clinic/role/index.vue',
  'clinic-disease': 'src/views/clinic/disease/index.vue',
  'clinic-options': 'src/views/clinic/options/index.vue',
  'user-patient': 'src/views/user/patient/index.vue',
  'inventory-drug': 'src/views/inventory/drug/index.vue',
  'inventory-standard': 'src/views/inventory/standard/index.vue',
  'inventory-order': 'src/views/inventory/order/index.vue',
  'system-menu': 'src/views/system/menu/index.vue',
  'system-domain': 'src/views/system/domain/index.vue',
  'common-dict': 'src/views/common/dict/index.vue',
  'thirdparty-authorize': 'src/views/thirdparty/authorize/index.vue',
  'project-acupuncture': 'src/views/project/acupuncture/index.vue',
  'project-boning': 'src/views/project/boning/index.vue',
  'project-cupping': 'src/views/project/cupping/index.vue',
  'statistics-revenue': 'src/views/statistics/revenue/index.vue',
}
async function retryImport(page) {
  try {
    // 加载页面资源
    switch (page) {
      case '403':
        return await import(`@/views/error-page/403.vue`)
      case '404':
        return await import(`@/views/error-page/404.vue`)
      case '404-guide':
        return await import(`@/views/error-page/404-guide.vue`)
      case 'login':
        // 这里demo演示，没有使用dynamic-import-vars
        return await import(`@/views/login/index.vue`)
      case 'dashboard':
        return await import(`@/views/dashboard/index.vue`)
      case 'institution-clinic':
        return await import(`@/views/institution/clinic/index.vue`)
      case 'institution-personnel':
        return await import(`@/views/institution/personnel/index.vue`)
      case 'clinic-employee':
        return await import(`@/views/clinic/employee/index.vue`)
      case 'clinic-role':
        return await import(`@/views/clinic/role/index.vue`)
      case 'clinic-disease':
        return await import(`@/views/clinic/disease/index.vue`)
      case 'clinic-options':
        return await import(`@/views/clinic/options/index.vue`)
      case 'user-patient':
        return await import(`@/views/user/patient/index.vue`)
      case 'inventory-drug':
        return await import(`@/views/inventory/drug/index.vue`)
      case 'inventory-standard':
        return await import(`@/views/inventory/standard/index.vue`)
      case 'inventory-order':
        return await import(`@/views/inventory/order/index.vue`)
      case 'system-menu':
        return await import(`@/views/system/menu/index.vue`)
      case 'system-domain':
        return await import(`@/views/system/domain/index.vue`)
      case 'common-dict':
        return await import(`@/views/common/dict/index.vue`)
      case 'thirdparty-authorize':
        return await import(`@/views/thirdparty/authorize/index.vue`)
      case 'project-acupuncture':
        return await import(`@/views/project/acupuncture/index.vue`)
      case 'project-boning':
        return await import(`@/views/project/boning/index.vue`)
      case 'project-cupping':
        return await import(`@/views/project/cupping/index.vue`)
      case 'statistics-revenue':
        return await import(`@/views/statistics/revenue/index.vue`)
      default:
        return await import(`@/views/error-page/404.vue`)
    }
  } catch (err: any) {
    console.log('判断是否是资源加载错误，错误重试 == ', err)
    //  判断是否是资源加载错误，错误重试
    if (
      err.toString().indexOf('Failed to fetch dynamically imported module') > -1
    ) {
      // todo
      window.location.reload()
      return
      // 获取manifest资源清单
      return fetch('/manifest.json').then(async (res) => {
        const json = await res.json()
        console.log('json == ', json)
        // 找到对应的最新版本的js
        // const errPage = `src/pages/${page}.vue`
        const errPage = `${retryImportErrPath?.[page]}`
        console.log('errPage == ', errPage)
        console.log('errPage 55 == ', errPage)
        // 加载新的js
        if (json?.[errPage]?.file) {
          return await import(`/${json[errPage].file}`)
        }
      })
    }
    throw err
  }
}

export default router
