/**
 * Desc:
 * Created Date: Friday, December 15th 2023, 4:08:18 pm
 * Author: jim Li
 * -----
 * Last Modified: Mon May 20 2024
 * Modified By: jim Li
 */

import dayjs from 'dayjs'
import { isNumber } from './types'

export function formatStrTime(
  str: string | number,
  option?: Record<string, any>,
) {
  if (!str) return ''
  if (isNumber(str) && String(str).length <= 10) {
    return dayjs.unix(Number(str)).format(option?.dateFormat ?? 'YYYY-MM-DD')
  }
  return dayjs(str).format(option?.dateFormat ?? 'YYYY-MM-DD')
}

// 格式化时间
export const formatDate = (
  timestamp?: string | number,
  op?: Record<string, any>,
) => {
  // console.log('timestamp == ', timestamp)
  // const { user } = useUserStore();
  // const isEn = user.lang === 'en-US';
  const format = op?.format || 'yy-MMMM-dd'
  const unit = op?.unit === '' ? op.unit : op?.unit || '-'
  const unDefault = op?.unDefault
  const date = timestamp ? new Date(timestamp) : !unDefault ? new Date() : ''
  if (!date) {
    return '- -'
  }
  const Y = date.getFullYear()
  const M =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  let D: number | string = date.getDate()
  D = D < 10 ? `0${D}` : D
  let HH: number | string = date.getHours()
  HH = HH < 10 ? `0${HH}` : HH
  let MM: number | string = date.getMinutes()
  MM = MM < 10 ? `0${MM}` : MM
  let SS: number | string = date.getSeconds()
  SS = SS < 10 ? `0${SS}` : SS
  const W = date.getDay()
  const wUnit = op?.wUnit || '周'
  // const WStr = isEn ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : [`${wUnit}日`, `${wUnit}一`, `${wUnit}二`, `${wUnit}三`, `${wUnit}四`, `${wUnit}五`, `${wUnit}六`];
  const WStr = [
    `${wUnit}日`,
    `${wUnit}一`,
    `${wUnit}二`,
    `${wUnit}三`,
    `${wUnit}四`,
    `${wUnit}五`,
    `${wUnit}六`,
  ]
  // const H = date.getHours()
  if (format === 'MMMM-dd hh:mm') {
    return `${M}${unit}${D} ${HH}:${MM}`
  } else if (format === 'yy-MMMM-dd hh:mm') {
    return `${Y}${unit}${M}${unit}${D} ${HH}:${MM}`
  } else if (format === 'MMMM-dd') {
    return `${M}${unit}${D}`
  } else if (format === 'MMMM-dd w') {
    return `${M}${unit}${D} ${WStr[W]}`
  } else if (format === 'MMMM-dd hh:mm w') {
    return `${M}${unit}${D} ${HH}:${MM} ${WStr[W]}`
  } else if (format === 'yy-MMMM-dd') {
    return `${Y}${unit}${M}${unit}${D}`
  } else if (format === 'yy-MMMM-dd w') {
    return `${Y}${unit}${M}${unit}${D} ${WStr[W]}`
  } else if (format === 'hh:mm') {
    return `${HH}:${MM}`
  } else if (format === 'mm') {
    return `${MM}`
  }
  return `${Y}${unit}${M}${unit}${D} ${HH}:${MM}:${SS}`
}

// 去除秒
export const delSeconds = (times?: string) => {
  if (!times) return
  const timesArr = times.split(':')
  return timesArr[0] + ':' + timesArr?.[1]
}

// 某個日期是否是今天
export const isToday = (times?: string) => {
  if (!times) return
  const todaytime = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
  if (new Date(times).getTime?.() === todaytime) {
    // 当天
    return true
  }
  return false
}

// 时分秒化中文单位
export const convertDate = (times: string, tMap: Record<string, any>) => {
  if (!times) return
  const timesArr = times.split(':')
  let str = ''
  if (timesArr.length === 1) {
    str += Number(timesArr[0]) + tMap?.['m']
  } else if (timesArr.length === 2) {
    if (Number(timesArr[0])) {
      str += Number(timesArr[0]) + tMap?.['h']
    }
    str += Number(timesArr[1]) + tMap?.['m']
  } else if (timesArr.length === 3) {
    if (Number(timesArr[0])) {
      str += Number(timesArr[0]) + tMap?.['h']
    }
    str += Number(timesArr[1]) + tMap?.['m']
    if (Number(timesArr[2]) && tMap?.['s']) {
      str += Number(timesArr[2]) + tMap['s']
    }
  }
  return str
}

// 日月年化中文单位
export const convertYDate = (
  times: string,
  spl: '-',
  tMap: Record<string, any>,
  option?: Record<string, any>,
) => {
  if (!times) return
  const timesStr = times.split(' ')?.[0]
  const timesStrEnd = times.split(' ')?.[1]
  const timesArr = timesStr.split(spl)
  let str = ''
  if (timesArr.length === 1) {
    str += Number(timesArr[0]) + tMap?.['d']
  } else if (timesArr.length === 2) {
    if (Number(timesArr[0])) {
      str += Number(timesArr[0]) + tMap?.['m']
    }
    str += Number(timesArr[1]) + tMap?.['d']
  } else if (timesArr.length === 3) {
    if (Number(timesArr[0])) {
      str += Number(timesArr[0]) + tMap?.['y']
    }
    str += Number(timesArr[1]) + tMap?.['m']
    if (Number(timesArr[2])) {
      str += Number(timesArr[2]) + tMap?.['d']
    }
  }
  // 上下午
  if (option?.ap && timesStrEnd) {
    const timesEndArr = timesStrEnd.split(':')
    const timesEndArrFirst = timesEndArr?.[0]
    const timesEndArrFirstNum = Number(timesEndArrFirst) || 0
    if (timesEndArrFirstNum < 12) {
      str += tMap?.['a']
    } else {
      str += tMap?.['p']
    }
  }
  return str
}
