/**
 * Desc:
 * Created Date: Friday, December 15th 2023, 4:09:22 pm
 * Author: jim Li
 * -----
 * Last Modified: Fri Dec 15 2023
 * Modified By: jim Li
 */

export const isNumber = (target) => typeof target === "number" && !isNaN(target)

export const isString = (target) =>
  typeof target === "string" && Object.prototype.toString.call(target) === "[object String]"

export const isArray = (target) => Array.isArray(target) && Object.prototype.toString.call(target) === "[object Array]"
